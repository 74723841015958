import { logout } from 'store/reducers/user';
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showToast } from 'utils/ToastContainers';
import { useTranslation } from 'react-i18next';

const useLogout = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('jwtToken', { path: '/' });
    dispatch(logout());
    showToast(t('TOASTMESSAGES.LOGGOUTOUTMESSAGE'), 'success');
    setTimeout(() => {
      history.push('/login');
    }, 500);
  };
  return { handleLogout };
};

export default useLogout;
