import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import { InitializeIcons } from 'config/iconsConfig';
import { ToastContainer } from 'react-toastify';
import AuthProtected from 'auth/AuthProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

InitializeIcons();
const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <AuthProtected>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
            <ToastContainer />
          </AuthProtected>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
