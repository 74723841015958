import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_API
      : process.env.REACT_APP_PROD_API
});

export default axiosInstance;
