import { Cookies } from 'react-cookie';
import axios from './axios-instance';

export const post = (endPoint, data, contentType, headers = {}) => {
  const cookies = new Cookies();
  const jwtToken = cookies.get('jwtToken') || '';
  return new Promise((resolve, reject) =>
    axios
      .post(endPoint, data, {
        headers: {
          'Content-Type': contentType || 'application/json',
          authorization: 'Bearer ' + jwtToken,
          ...headers
        }
      })
      .then((result) => resolve(result.data))
      .catch((error) => reject(error))
  );
};
