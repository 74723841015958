import React from 'react';
import PropTypes from 'prop-types';

const ExampleWrapperSeamless = (props) => {
  const { sectionHeading, children } = props;

  return (
    <div className="example-card-seamless mb-5">
      {sectionHeading && <h5 className="display-4 mb-4 font-weight-bold">{sectionHeading}</h5>}
      {children}
    </div>
  );
};

ExampleWrapperSeamless.propTypes = {
  children: PropTypes.node,
  sectionHeading: PropTypes.string
};

export default ExampleWrapperSeamless;
