import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { decodeJWT } from './utils';
import { getUser } from 'store/reducers/user';
import { useDispatch } from 'react-redux';

const AuthProtected = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('jwtToken'); // currently it's just expiry time, not jwt token
    const isForgotPasswordRoute = location.pathname === '/forgot-password';
    if (!token && !isForgotPasswordRoute) {
      history.push(
        location.pathname === '/login' ? '/login' : `/login?redirect=${location.pathname}`
      );
    }
    if (token) {
      const isValidToken = decodeJWT(token);
      if (!isValidToken) {
        cookies.remove('jwtToken', { path: '/' });
        history.push(
          location.pathname === '/login'
            ? '/login'
            : `/login?redirect=${location.pathname + location.search}`
        );
      } else {
        dispatch(getUser());
        location.pathname === '/login' && history.push('/dashboard');
      }
    }
  }, [location.pathname]);
  return children;
};

export default AuthProtected;
