import { UPDATE_ADMIN, UPDATE_PARTNER, patch } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'store/reducers/user';
import { showToast } from 'utils/ToastContainers';
import { prepareFormData } from './utils';
import { useTranslation } from 'react-i18next';

const useUpdateUser = () => {
  const { t } = useTranslation();
  const { id, roleId } = useSelector((state) => ({ id: state.user.id, roleId: state.user.roleId }));
  const dispatch = useDispatch();
  const handleUdpateUser = async (values) => {
    try {
      const formData = prepareFormData(values);
      const updateUserRoute = roleId === 1 ? UPDATE_ADMIN : UPDATE_PARTNER;
      const response = await patch(updateUserRoute + id, formData, 'multipart/form-data');
      dispatch(updateUser(response.user));
      showToast(t('TOASTMESSAGES.UPDATEADMINMESSAGE'), 'success');
    } catch (error) {
      console.log('error', error);
      showToast(error?.response?.data?.message || error?.message, 'error');
    }
  };
  return { handleUdpateUser };
};

export default useUpdateUser;
