import React from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarToggle, setSidebarToggleMobile } from '../../store/reducers/themeSlice';
import { UncontrolledTooltip } from 'reactstrap';

const SidebarToggle = () => {
  const { sidebarToggle, sidebarToggleMobile } = useSelector((state) => ({
    sidebarToggle: state.theme.sidebarToggle,
    sidebarToggleMobile: state.theme.sidebarToggleMobile
  }));
  const dispatch = useDispatch();
  return (
    <>
      <button
        onClick={() => dispatch(setSidebarToggle(!sidebarToggle))}
        className="btn btn-sm collapse-sidebar-btn d-none d-lg-block"
        id="CollapseSidebarTooltip">
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <UncontrolledTooltip
        target="CollapseSidebarTooltip"
        container=".app-sidebar"
        placement="right">
        {sidebarToggle ? 'Expand sidebar' : 'Collapse sidebar'}
      </UncontrolledTooltip>
      <button
        className={clsx('btn btn-sm  toggle-mobile-sidebar-btn d-lg-none', {
          'is-active': sidebarToggleMobile
        })}
        onClick={() => dispatch(setSidebarToggleMobile(!sidebarToggleMobile))}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </>
  );
};

export default SidebarToggle;
