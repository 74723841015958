export const statusClass = (status) => {
  let styleClass;
  switch (status) {
    case 'Pending':
      styleClass = 'info';
      break;
    case 'Confirmed':
      styleClass = 'primary';
      break;
    case 'Processing':
      styleClass = 'info';
      break;
    case 'Partner Started':
      styleClass = 'info';
      break;
    case 'Delivered':
      styleClass = 'success';
      break;
    case 'Returned':
      styleClass = 'warning';
      break;
    case 'Failed':
      styleClass = 'danger';
      break;
    case 'Cancelled':
      styleClass = 'dark';
      break;

    default:
      break;
  }
  return styleClass;
};
