import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required')
});

export const prepareFormData = (values) => {
  const formData = new FormData();
  formData.append('firstName', values.firstName);
  formData.append('lastName', values.lastName);
  formData.append('email', values.email);
  formData.append('phone', values.phone);
  if (typeof values.image === 'object') {
    formData.append('image', values.image);
  }
  return formData;
};
