import React, { useState } from 'react';

import clsx from 'clsx';

import { Collapse, Badge } from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../store/reducers/themeSlice';

import {
  ChevronRight,
  ShoppingBag,
  Link,
  Server,
  Image,
  Star,
  Clock,
  Users,
  UserPlus,
  Settings,
  Archive,
  DollarSign,
  User,
  Phone
} from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SidebarMenu = () => {
  const { t } = useTranslation();
  const roleId = useSelector((state) => state.user.roleId);
  const dispatch = useDispatch();
  const toggleSidebarMobile = () => dispatch(setSidebarToggleMobile(false));

  const [ordersOpen, setOrdersOpen] = useState(false);
  const [bannersOpen, setBannersOpen] = useState(false);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [partnersOpen, setPartnersOpen] = useState(false);
  const [adminsOpen, setAdminsOpen] = useState(false);
  const [customersOpen, setCustomersOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const toggleOrders = (event) => {
    event.preventDefault();
    setOrdersOpen((prev) => !prev);
  };

  const toggleCetegories = (event) => {
    event.preventDefault();
    setCategoriesOpen((prev) => !prev);
  };
  const toggleServices = (event) => {
    event.preventDefault();
    setServicesOpen((prev) => !prev);
  };

  const toggleBanners = (event) => {
    setBannersOpen((prev) => !prev);
    event.preventDefault();
  };

  const togglePartners = (event) => {
    event.preventDefault();
    setPartnersOpen((prev) => !prev);
  };

  const toggleAdmins = (event) => {
    event.preventDefault();
    setAdminsOpen((prev) => !prev);
  };

  const toggleCustomers = (event) => {
    event.preventDefault();
    setCustomersOpen((prev) => !prev);
  };

  const toggleSettings = (event) => {
    event.preventDefault();
    setSettingsOpen((prev) => !prev);
  };

  return (
    <PerfectScrollbar>
      <div className="sidebar-navigation">
        {roleId === 1 ? (
          <>
            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/dashboard">
                  <span className="sidebar-icon">
                    <Archive />
                  </span>
                  <span className="sidebar-item-label">{t('NAV.DASHBOARD')}</span>
                </NavLink>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>{t('NAV.ORDERSSECTION.TITLE')}</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleOrders} className={clsx({ active: ordersOpen })}>
                  <span className="sidebar-icon">
                    <ShoppingBag />
                  </span>
                  <span className="sidebar-item-label">{t('NAV.ORDERSSECTION.ORDERS.TITLE')}</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={ordersOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders">
                        {t('NAV.ORDERSSECTION.ORDERS.ALL')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/pending">
                        {t('NAV.ORDERSSECTION.ORDERS.PENDING')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/confirmed">
                        {t('NAV.ORDERSSECTION.ORDERS.CONFIRMED')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/processing">
                        {t('NAV.ORDERSSECTION.ORDERS.PROCESSING')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/partner-started">
                        {t('NAV.ORDERSSECTION.ORDERS.PARTNERSTARTED')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/delivered">
                        {t('NAV.ORDERSSECTION.ORDERS.DELIVERED')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/returned">
                        {t('NAV.ORDERSSECTION.ORDERS.RETURENED')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/cancelled">
                        {t('NAV.ORDERSSECTION.ORDERS.CANCELLED')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/failed">
                        {t('NAV.ORDERSSECTION.ORDERS.FAILED')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>{t('NAV.SERVICESSECTION.TITLE')}</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleBanners} className={clsx({ active: bannersOpen })}>
                  <span className="sidebar-icon">
                    <Image />
                  </span>
                  <span className="sidebar-item-label">
                    {t('NAV.SERVICESSECTION.BANNERS.TITLE')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={bannersOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/banners/new">
                        {t('NAV.SERVICESSECTION.ADDNEW')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/banners">
                        {t('NAV.SERVICESSECTION.LIST')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  href="#/"
                  onClick={toggleCetegories}
                  className={clsx({ active: categoriesOpen })}>
                  <span className="sidebar-icon">
                    <Link />
                  </span>
                  <span className="sidebar-item-label">
                    {t('NAV.SERVICESSECTION.CATEGORY.TITLE')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={categoriesOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/categories/new">
                        {t('NAV.SERVICESSECTION.ADDNEW')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/categories">
                        {t('NAV.SERVICESSECTION.LIST')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#/" onClick={toggleServices} className={clsx({ active: servicesOpen })}>
                  <span className="sidebar-icon">
                    <Server />
                  </span>
                  <span className="sidebar-item-label">
                    {t('NAV.SERVICESSECTION.SERVICES.TITLE')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={servicesOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/services/new">
                        {t('NAV.SERVICESSECTION.ADDNEW')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/services">
                        {t('NAV.SERVICESSECTION.LIST')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>{t('NAV.BUSINESSSECTION.TITLE')}</span>
            </div>

            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/time-slots">
                  <span className="sidebar-icon">
                    <Clock />
                  </span>
                  <span className="sidebar-item-label">{t('NAV.BUSINESSSECTION.TIMESLOT')}</span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/reviews">
                  <span className="sidebar-icon">
                    <Star />
                  </span>
                  <span className="sidebar-item-label">{t('NAV.BUSINESSSECTION.REVIEW')}</span>
                  <span className="ml-auto">
                    <Badge color="success" pill>
                      0
                    </Badge>
                  </span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/payments">
                  <span className="sidebar-icon">
                    <DollarSign />
                  </span>
                  <span className="sidebar-item-label">Payments</span>
                  <span className="ml-auto">
                    <Badge color="success" pill>
                      0
                    </Badge>
                  </span>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/appointments">
                  <span className="sidebar-icon">
                    <User />
                  </span>
                  <span className="sidebar-item-label">Appointments</span>
                  <span className="ml-auto">
                    <Badge color="success" pill>
                      0
                    </Badge>
                  </span>
                </NavLink>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>{t('NAV.SETTINGSSECTION.TITLE')}</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleSettings} className={clsx({ active: settingsOpen })}>
                  <span className="sidebar-icon">
                    <Settings />
                  </span>
                  <span className="sidebar-item-label">
                    {t('NAV.SETTINGSSECTION.SETTINGS.TITLE')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={settingsOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/store-setup">
                        {t('NAV.SETTINGSSECTION.SETTINGS.STORESETUP')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/mail-config">
                        {t('NAV.SETTINGSSECTION.SETTINGS.MAILCONFIG')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/sms-module">
                        {t('NAV.SETTINGSSECTION.SETTINGS.SMSMODULE')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/payment-config">
                        {t('NAV.SETTINGSSECTION.SETTINGS.PAYMENTCONFIG')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/plan-config">
                        {t('NAV.SETTINGSSECTION.SETTINGS.PLANCONFIG')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/terms">
                        {t('NAV.SETTINGSSECTION.SETTINGS.TERMSANDCONDITIONS')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/settings/privacy-policy">
                        {t('NAV.SETTINGSSECTION.SETTINGS.PRIVACYPOLICY')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>

            <div className="sidebar-header">
              <span>{t('NAV.PARTNERSSECTION.TITLE')}</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={togglePartners} className={clsx({ active: partnersOpen })}>
                  <span className="sidebar-icon">
                    <Users />
                  </span>
                  <span className="sidebar-item-label">
                    {t('NAV.PARTNERSSECTION.PARTNERS.TITLE')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={partnersOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/partners/new">
                        {t('NAV.PARTNERSSECTION.PARTNERS.ADDNEW')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/partners">
                        {t('NAV.PARTNERSSECTION.PARTNERS.LIST')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/partners/subscribers">
                        {t('NAV.PARTNERSSECTION.PARTNERS.SUBSCRIBERS')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/partners/chat">
                        {t('NAV.PARTNERSSECTION.PARTNERS.CHAT')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>{t('NAV.ADMINSECTION.TITLE')}</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleAdmins} className={clsx({ active: adminsOpen })}>
                  <span className="sidebar-icon">
                    <UserPlus />
                  </span>
                  <span className="sidebar-item-label">{t('NAV.ADMINSECTION.ADMINS.TITLE')}</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={adminsOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/admins/new">
                        {t('NAV.ADMINSECTION.ADMINS.ADDNEW')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/admins">
                        {t('NAV.ADMINSECTION.ADMINS.LIST')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>{t('NAV.CUSTOMERSECTION.TITLE')}</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleCustomers} className={clsx({ active: customersOpen })}>
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={['far', 'user-circle']} />
                  </span>
                  <span className="sidebar-item-label">
                    {t('NAV.CUSTOMERSECTION.CUSTOMERS.TITLE')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={customersOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/customers">
                        {t('NAV.CUSTOMERSECTION.CUSTOMERS.LIST')}
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/customers-contacts">
                  <span className="sidebar-icon">
                    <Phone />
                  </span>
                  <span className="sidebar-item-label">Customer Contacts</span>
                  <span className="ml-auto">
                    <Badge color="success" pill>
                      0
                    </Badge>
                  </span>
                </NavLink>
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul>
              <li>
                <NavLink onClick={toggleSidebarMobile} to="/dashboard">
                  <span className="sidebar-icon">
                    <Archive />
                  </span>
                  <span className="sidebar-item-label">Dashboard</span>
                </NavLink>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>Order Section</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleOrders} className={clsx({ active: ordersOpen })}>
                  <span className="sidebar-icon">
                    <ShoppingBag />
                  </span>
                  <span className="sidebar-item-label">Orders</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={ordersOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders">
                        All
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/pending">
                        Pending
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/confirmed">
                        Confirmed
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/processing">
                        Processing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/partner-started">
                        Partner Started
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/delivered">
                        Delivered
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/returned">
                        Returned
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/cancelled">
                        Cancelled
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/orders/failed">
                        Failed
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>Services Section</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={toggleServices} className={clsx({ active: servicesOpen })}>
                  <span className="sidebar-icon">
                    <Server />
                  </span>
                  <span className="sidebar-item-label">Services</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={servicesOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/services/new">
                        Add New
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/services">
                        List
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
            <div className="sidebar-header">
              <span>Partners Section</span>
            </div>
            <ul>
              <li>
                <a href="#/" onClick={togglePartners} className={clsx({ active: partnersOpen })}>
                  <span className="sidebar-icon">
                    <Users />
                  </span>
                  <span className="sidebar-item-label">Partners</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={partnersOpen}>
                  <ul>
                    <li>
                      <NavLink onClick={toggleSidebarMobile} to="/partners/chat">
                        Chat
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          </>
        )}
      </div>
    </PerfectScrollbar>
  );
};

export default SidebarMenu;
