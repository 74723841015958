import React from 'react';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import FileUpload from './FileUpload';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { validationSchema } from './utils';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import useUpdateUser from './useUpdateUser';

const SettingModal = ({ open, toggleModal }) => {
  const user = useSelector((state) => state.user);
  const { handleUdpateUser } = useUpdateUser();
  return (
    <Modal
      centered
      size="xl"
      isOpen={open}
      zIndex={1300}
      toggle={toggleModal}
      contentClassName="border-0 bg-transparent">
      <Formik
        initialValues={user}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleUdpateUser}>
        {({ touched, errors, handleSubmit, values, setFieldValue, dirty, isSubmitting }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <div className="bg-white rounded p-4">
              <Row className="no-gutters" spacing={2}>
                <Col md="5">
                  <FileUpload setFieldValue={setFieldValue} />
                </Col>
                <Col md="7">
                  <FormGroup>
                    <Label htmlFor="fistName">First Name</Label>
                    <Input
                      type="text"
                      tag={Field}
                      name="firstName"
                      placeholder="Enter first name"
                      invalid={!!errors.firstName && touched.firstName}
                    />
                    <FormFeedback>{errors.firstName}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="lastName">Last Name</Label>
                    <Input
                      type="text"
                      tag={Field}
                      name="lastName"
                      id="lastName"
                      placeholder="Enter last name"
                      invalid={!!errors.lastName && touched.lastName}
                    />
                    <FormFeedback>{errors.lastName}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phone">Phone</Label>
                    <PhoneInput
                      defaultCountry="US"
                      value={values.phone}
                      name="phone"
                      error={
                        values.phone
                          ? isValidPhoneNumber(values.phone)
                            ? undefined
                            : 'Invalid phone number'
                          : 'Phone number required'
                      }
                      onChange={(value) => setFieldValue('phone', value)}
                    />
                    <FormFeedback>{touched.phone && errors.phone}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="text"
                      tag={Field}
                      name="email"
                      id="email"
                      placeholder="Enter email"
                      invalid={!!errors.email && touched.email}
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <div className="d-flex justify-content-center">
                    <Button color="primary" type="submit" disabled={isSubmitting || !dirty}>
                      Submit
                    </Button>
                    <Button color="secondary" className="mt-1 mx-2" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

SettingModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default SettingModal;
