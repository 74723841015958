import React from 'react';

import { NavLink } from 'react-router-dom';

import projectLogo from '../../assets/logos/prolink-icon.jpg';
import { useTranslation } from 'react-i18next';

export default function SidebarHeader() {
  const { t } = useTranslation();
  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink to="/" title="prolink" className="app-sidebar-logo">
            {/* <div className="app-sidebar-logo--icon"> */}
            <img alt="prolink" src={projectLogo} width={40} height={40} />
            {/* </div> */}
            <div className="app-sidebar-logo--text">
              <b className="text-capitalize">{t('LOGO.TITLE')}</b>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
}
