import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files

import translationEN from './locales/en/en.json';
import translationAM from './locales/am/am.json';
import translationRU from './locales/ru/ru.json';

const resources = {
  en: {
    translation: translationEN
  },
  am: {
    translation: translationAM
  },
  ru: {
    translation: translationRU
  }
};

const storedLanguage = sessionStorage.getItem('selectedLanguage');

i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage ? storedLanguage : 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
