import React, { useEffect, useState } from 'react';

import { UncontrolledPopover, Badge, Button } from 'reactstrap';

import { Bell } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Cookies } from 'react-cookie';
import { io } from 'socket.io-client';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showToast } from 'utils/ToastContainers';
import * as API from 'api';
import { useSelector } from 'react-redux';
import { ADMIN_ROLE } from 'constants';

const cookies = new Cookies();
const baseURL =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PROD_API;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const user = useSelector((state) => state.user);

  const socketIo = io(baseURL, {
    auth: {
      token: cookies.get('jwtToken') || ''
    }
  });

  useEffect(() => {
    if (user?.roleId === ADMIN_ROLE) {
      socketIo.on('connect', function () {
        console.log('Made socket connection', socketIo.id);
      });
      // Listen for unread notifications when the socket connects or reconnects
      socketIo.on('notifications', (data) => {
        data.sort((x) => (x?.markRead ? 1 : -1));
        setNotifications(data);
      });
      // Listen for events from the server
      socketIo.on('notification', (data) => {
        setNotifications([data, ...notifications]);
      });
    }

    return () => {
      // Disconnect the socket when the component unmounts
      socketIo.disconnect();
    };
  }, [user]);

  const handleMarkNotificationsAsRead = async () => {
    try {
      await API.post(API.MARK_NOTIFICATIONS_AS_READ);
      const updatedNotifications = [...notifications].map((notification) => ({
        ...notification,
        markRead: true
      }));
      setNotifications(updatedNotifications);
      showToast('Notifications marked as read', 'success');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleMarkNotificationAsRead = async (index) => {
    try {
      const notification = notifications[index];
      await API.post(API.MARK_NOTIFICATION_AS_READ + notification.id);
      const updatedNotifications = notifications;
      updatedNotifications[index] = {
        ...notification,
        markRead: true
      };
      setNotifications([...updatedNotifications]);
      showToast('Notification marked as read', 'success');
    } catch (error) {
      console.log('error', error);
    }
  };
  const unmarkedCount = notifications.reduce(
    (total, notification) => (notification.markRead ? total : total + 1),
    0
  );
  return (
    <React.Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
        <span className="d-inline-block pr-2">
          <Button
            id="alertsPopover"
            color="neutral-success"
            className="bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
            <span>
              {!!unmarkedCount && (
                <Badge color="success" className="badge-circle">
                  New notifications
                </Badge>
              )}
              <Bell />
            </span>
          </Button>
          <UncontrolledPopover
            target="alertsPopover"
            trigger="legacy"
            className="popover-custom-wrapper popover-custom-md"
            placement="auto">
            <div className="bg-composed-wrapper bg-midnight-bloom mx-3 mt-3 border-0 text-center rounded-sm">
              <div className="bg-composed-img-3 bg-composed-wrapper--image" />
              <div className="bg-composed-wrapper--content text-white px-2 py-4">
                <h4 className="font-size-xl font-weight-bold mb-2">Notifications</h4>
                <p className="opacity-8 mb-0">
                  You have <b className="text-success">{unmarkedCount}</b> new messages
                </p>
              </div>
            </div>
            <div className="scroll-area scroll-area-sm shadow-overflow">
              <PerfectScrollbar
                options={{
                  wheelPropagation: false
                }}>
                <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                  {notifications.map((notification, index) => (
                    <div className="timeline-item" key={index}>
                      <div className="timeline-item-offset">
                        {moment(notification?.createdAt).format('hh:mm A')}
                      </div>
                      <div className="timeline-item--content">
                        <div className="timeline-item--icon" />
                        <h4 className="timeline-item--label mb-2 font-weight-bold">
                          {notification?.type === 1 ? 'Payment' : 'Subscription is expiring soon'}
                        </h4>
                        <p>{notification?.message}</p>
                        <div className="mt-2">
                          <Button
                            size="sm"
                            outline
                            color="info"
                            disabled={notification.markRead}
                            className="ml-1"
                            onClick={async () => {
                              if (!notification.markRead) {
                                await handleMarkNotificationAsRead(index);
                              }
                            }}
                            title="Mark as read">
                            {notification.markRead ? (
                              <FontAwesomeIcon icon="fa-regular fa-envelope-open" />
                            ) : (
                              <FontAwesomeIcon icon="fa-regular fa-envelope" />
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </PerfectScrollbar>
            </div>
            <div className="text-center py-3">
              <Button
                color="primary"
                className="px-4 btn-gradient badge-wrapper bg-midnight-bloom"
                disabled={unmarkedCount === 0}
                onClick={handleMarkNotificationsAsRead}>
                <span className="btn-wrapper--label">Mark all as read</span>
              </Button>
            </div>
          </UncontrolledPopover>
        </span>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
