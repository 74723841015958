import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { Power, Settings } from 'react-feather';
import SettingModal from './SettingModal';
import useLogout from './useLogout';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HeaderUserbox = () => {
  const [modal, setModal] = useState(false);
  const { handleLogout } = useLogout();
  const user = useSelector((state) => state.user);
  const toggleModal = () => setModal(!modal);
  const { t } = useTranslation();

  return (
    <>
      <SettingModal open={modal} toggleModal={toggleModal} />
      <UncontrolledDropdown className="position-relative ml-2">
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex btn-transition-none align-items-center">
          <div className="d-block p-0 avatar-icon-wrapper">
            <div className="avatar-icon rounded">
              <img
                src={
                  user.image ||
                  'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
                }
                alt="..."
              />
            </div>
          </div>
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold">{user.firstName + ' ' + user.lastName}</div>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          <ListGroup flush className="text-left bg-transparent">
            <ListGroupItem className="rounded-top p-0">
              <Nav pills className="nav-neutral-primary flex-column">
                <NavItem>
                  <NavLinkStrap onClick={toggleModal} className="d-flex">
                    <Settings size={16} className="mr-2" />
                    {t('USERDROPDOWN.SETTINGS')}
                  </NavLinkStrap>
                </NavItem>
                {/* <NavItem>
                  <NavLinkStrap href="#/" onClick={(e) => e.preventDefault()} className="d-flex">
                    <ZoomIn size={16} className="mr-2" />
                    {t('USERDROPDOWN.ZOOM')}
                  </NavLinkStrap>
                </NavItem> */}
                <NavItem>
                  <NavLinkStrap onClick={handleLogout} className="d-flex">
                    <Power size={16} className="mr-2" />
                    {t('USERDROPDOWN.LOGOUT')}
                  </NavLinkStrap>
                </NavItem>
              </Nav>
            </ListGroupItem>
          </ListGroup>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default HeaderUserbox;
