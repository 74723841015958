// roles
export const ADMIN_ROLE = 1;
export const PARTNER_ROLE = 1;

// order status
export const PENDING = 'Pending';
export const CONFIRMED = 'Confirmed';
export const PROCESSING = 'Processing';
export const PARTNER_STARTED = 'Partner Started';
export const DELIVERED = 'Delivered';
export const RETURNED = 'Returned';
export const CANCELLED = 'Cancelled';
export const FAILED = 'Failed';

// order statuses array
export const orderStatuses = [
  PENDING,
  CONFIRMED,
  PROCESSING,
  PARTNER_STARTED,
  DELIVERED,
  RETURNED,
  CANCELLED,
  FAILED
];
