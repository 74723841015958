import React, { useState, useEffect } from 'react';
import { get, GET_SUBSCRIPTION_PLANS } from 'api';
import clsx from 'clsx';
import { NavLink as NavLinkStrap } from 'reactstrap';
import PlanConfigTab from './PlanConfigTab';
import { Card, TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { showToast } from 'utils/ToastContainers';
import useHandlePlanConfig from './useHandlePlanConfig';
import { useTranslation } from 'react-i18next';

export default function PlanConfigComponent() {
  const [activeTab, setActiveTab] = useState('1');
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const { handleSubmitPlanConfig } = useHandlePlanConfig(activeTab);
  const { t } = useTranslation();

  const toggle = (tab) => setActiveTab(tab);

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const subscriptionPlans = await get(GET_SUBSCRIPTION_PLANS);
        const sortedSubscriptionPlans = subscriptionPlans.sort((a, b) => (a.id > b.id ? 1 : -1));
        setSubscriptionPlans(sortedSubscriptionPlans);
      } catch (error) {
        showToast(error?.response?.data?.message || error?.message, 'error');
      }
    };
    fetchSubscriptionPlans();
  }, []);

  const handleSubmitPlan = async (values, ...args) => {
    try {
      await handleSubmitPlanConfig(values, ...args);
      const updatingSubPlanIndex = parseInt(activeTab);
      const updatedSubPlans = [...subscriptionPlans];
      updatedSubPlans[updatingSubPlanIndex - 1] = values;
      setSubscriptionPlans([...updatedSubPlans]);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Card className="shadow-xxl p-3 mb-5">
      <div className="nav-tabs-primary">
        <Nav tabs>
          <NavItem>
            <NavLinkStrap
              className={clsx({ active: activeTab === '1' })}
              onClick={() => toggle('1')}>
              {t('SETTINGS.PLANCONFIG.SUBSCRIPTIONTAB3TITLE')}
            </NavLinkStrap>
          </NavItem>
          <NavItem>
            <NavLinkStrap
              className={clsx({ active: activeTab === '2' })}
              onClick={() => toggle('2')}>
              {t('SETTINGS.PLANCONFIG.SUBSCRIPTIONTAB6TITLE')}
            </NavLinkStrap>
          </NavItem>
          <NavItem>
            <NavLinkStrap
              className={clsx({ active: activeTab === '3' })}
              onClick={() => toggle('3')}>
              {t('SETTINGS.PLANCONFIG.SUBSCRIPTIONTAB12TITLE')}
            </NavLinkStrap>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PlanConfigTab planConfig={subscriptionPlans?.[0]} handleSubmitPlan={handleSubmitPlan} />
        </TabPane>
        <TabPane tabId="2">
          <PlanConfigTab planConfig={subscriptionPlans?.[1]} handleSubmitPlan={handleSubmitPlan} />
        </TabPane>
        <TabPane tabId="3">
          <PlanConfigTab planConfig={subscriptionPlans?.[2]} handleSubmitPlan={handleSubmitPlan} />
        </TabPane>
      </TabContent>
    </Card>
  );
}
