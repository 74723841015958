import React from 'react';
import PropTypes from 'prop-types';
import { CardBody, Card, CardHeader } from 'reactstrap';

const CardWrapper = ({ children, sectionHeading }) => {
  return (
    <Card className="card-box mb-5 flex-1">
      {sectionHeading && (
        <CardHeader>
          <div className="card-header--title font-size-md font-weight-bold py-2">
            {sectionHeading}
          </div>
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
    </Card>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.node,
  sectionHeading: PropTypes.string
};

export default CardWrapper;
