import React from 'react';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PieChart } from 'react-feather';

const PageTitle = ({ icon, titleHeading, titleDescription }) => {
  const { pageTitleStyle, pageTitleBackground, pageTitleShadow, pageTitleDescription } =
    useSelector((state) => ({
      pageTitleStyle: state.theme.pageTitleStyle,
      pageTitleBackground: state.theme.pageTitleBackground,
      pageTitleShadow: state.theme.pageTitleShadow,
      pageTitleIconBox: state.theme.pageTitleIconBox,
      pageTitleDescription: state.theme.pageTitleDescription
    }));

  return (
    <div
      className={clsx('app-page-title mb-3', pageTitleStyle, pageTitleBackground, {
        'app-page-title--shadow': pageTitleShadow
      })}>
      <div>
        <div className="app-page-title--first">
          {icon && (
            <div className="app-page-title--iconbox d-70">
              <div className="d-70 d-flex align-items-center justify-content-center">
                <PieChart className="display-2 text-primary" />
              </div>
            </div>
          )}
          <div className="app-page-title--heading">
            <h1>{titleHeading}</h1>
            {pageTitleDescription && (
              <div className="app-page-title--description">{titleDescription}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  icon: PropTypes.bool,
  titleHeading: PropTypes.string,
  titleDescription: PropTypes.string
};
export default PageTitle;
