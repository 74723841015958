import { Cookies } from 'react-cookie';
import axios from './axios-instance';

export const get = (endPoint) => {
  const cookies = new Cookies();
  const jwtToken = cookies.get('jwtToken') || '';
  return new Promise((resolve, reject) =>
    axios
      .get(endPoint, {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + jwtToken
        }
      })
      .then((result) => resolve(result.data))
      .catch((error) => reject(error))
  );
};
