import clsx from 'clsx';
import React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PartnerStartedOrdersTable from './table';
import { useTranslation } from 'react-i18next';

const OrderTab = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="nav-tabs-dark tabs-animated tabs-animated-line">
        <Nav tabs className="mb-5">
          <NavItem>
            <NavLink className={clsx({ active: true })}>
              <span className="font-size-lg text-black text-capitalize px-3 py-2">
                {t('ORDERS.PARTNERSTARTED.TABTITLE')}
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab="1">
        <TabPane tabId="1">
          <PartnerStartedOrdersTable />
        </TabPane>
      </TabContent>
    </>
  );
};

export default OrderTab;
