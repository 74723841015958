import * as yup from 'yup';

export const initialValues = {
  nameInEnglish: '',
  nameInRussian: '',
  price: ''
};

export const validationSchema = yup.object().shape({
  nameInEnglish: yup.string().required('English Name is required'),
  nameInRussian: yup.string().required('Russian Name is required'),
  price: yup.number().required('Price is required')
});
