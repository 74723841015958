import React from 'react';
import { PageTitle } from 'layout-components';
import PlanConfigComponent from './components/PlanConfigComponent';
import { useTranslation } from 'react-i18next';

export default function PlanConfig() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle titleHeading={t('SETTINGS.PLANCONFIG.TITLE')} />
      <PlanConfigComponent />
    </>
  );
}
