import { patch, UPDATE_SUBSCRIPTION_PLAN } from 'api';
import { showToast } from 'utils/ToastContainers';
import { useTranslation } from 'react-i18next';

const useHandlePlanConfig = (subscriptionId) => {
  const { t } = useTranslation();

  const handleSubmitPlanConfig = async (values, { setSubmitting }) => {
    try {
      await patch(UPDATE_SUBSCRIPTION_PLAN + subscriptionId, values);
      showToast(t('TOASTMESSAGES.SUBSCRIPTIONUPDATEMESSAGE'), 'success');
      setSubmitting(false);
    } catch (error) {
      console.log('error', error);
      setSubmitting(false);
      showToast(error?.response?.data?.message || error?.message, 'error');
    }
  };
  return { handleSubmitPlanConfig };
};

export default useHandlePlanConfig;
