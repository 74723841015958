import React from 'react';
import { Formik, Form, Field } from 'formik';
import { initialValues, validationSchema } from './utils';
import { Label, FormGroup, Input, Container, Button, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function PlanConfigTab({ planConfig, handleSubmitPlan }) {
  const { t } = useTranslation();
  return (
    <Container className="mt-5">
      <Formik
        initialValues={planConfig || initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmitPlan}>
        {({ touched, errors, dirty, isSubmitting }) => (
          <Form>
            <FormGroup>
              <Label htmlFor="exampleEmail">{t('SETTINGS.PLANCONFIG.MEMBERSHIPNAMEEN')} </Label>
              <Input
                type="text"
                name="nameInEnglish"
                tag={Field}
                invalid={!!errors.nameInEnglish && touched.nameInEnglish}
              />
              <FormFeedback>{t('SETTINGS.PLANCONFIG.MEMBERSHIPNAMEENPLACEHOLDER')}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="exampleEmail">{t('SETTINGS.PLANCONFIG.MEMBERSHIPNAMERU')}</Label>
              <Input
                type="text"
                name="nameInRussian"
                tag={Field}
                invalid={!!errors.nameInRussian && touched.nameInRussian}
              />
              <FormFeedback>{t('SETTINGS.PLANCONFIG.MEMBERSHIPNAMERUPLACEHOLDER')}</FormFeedback>
            </FormGroup>
            {/* <FormGroup>
              <Label htmlFor="exampleEmail">{t('SETTINGS.PLANCONFIG.MEMBERSHIPNAMEAM')}</Label>
              <Input
                type="text"
                name="nameInArmenian"
                tag={Field}
                invalid={!!errors.nameInArmenian && touched.nameInArmenian}
              />
              <FormFeedback>{t('SETTINGS.PLANCONFIG.MEMBERSHIPNAMEAMPLACEHOLDER')}</FormFeedback>
            </FormGroup> */}
            <FormGroup>
              <Label htmlFor="exampleEmail">{t('SETTINGS.PLANCONFIG.PRICE')}</Label>
              <Input
                type="text"
                name="price"
                tag={Field}
                invalid={!!errors.price && touched.price}
              />
              <FormFeedback>{t('SETTINGS.PLANCONFIG.PRICEVALIDATIONMSG')}</FormFeedback>
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              className="mt-2"
              disabled={isSubmitting || !dirty}>
              {isSubmitting ? (
                <span
                  className="btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : planConfig ? (
                t('SETTINGS.PLANCONFIG.UPDATEBUTTONTITLE')
              ) : (
                t('SETTINGS.PLANCONFIG.SUBMITBUTTONTITLE')
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

PlanConfigTab.propTypes = {
  planConfig: PropTypes.object,
  handleSubmitPlan: PropTypes.func
};
