import React, { useEffect, useState } from 'react';
import CardWrapper from 'components/CardWrapper';

import { Button, Container } from 'reactstrap';
import ReactQuill from 'react-quill';
import { showToast } from 'utils/ToastContainers';
import { ADD_UPDATE_TERMS, GET_TERMS, get, post } from 'api';
import { useTranslation } from 'react-i18next';
// '<h1><strong>Terms of Use</strong></h1><p>It is a long established fact that a reader will be distracted by.</p><p><br></p><h1><strong>Interpretations and Definitions</strong></h1><p><br></p><h2>Interpretations</h2><p><br></p><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.</p><p><br></p><h2>Interpretations</h2><p><br></p><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.</p>'

export default function TermsAndConditionsComponent() {
  const [content, setContent] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const res = await get(GET_TERMS);
        if (res.terms) setContent(res.terms);
      } catch (error) {
        showToast(error?.response?.data?.message || error?.message, 'error');

        console.log('error', error);
      }
    };
    fetchTerms();
  }, []);

  const handleSubmitTerms = async () => {
    try {
      await post(ADD_UPDATE_TERMS, { content: content.content });
      showToast(t('TOASTMESSAGES.TERMSSAVEMESSAGE'), 'success');
    } catch (error) {
      showToast(error?.response?.data?.message || error?.message, 'error');

      console.log('error', error);
    }
  };

  return (
    <>
      <CardWrapper>
        <Container>
          <ReactQuill
            theme="snow"
            value={content.content || ''}
            onChange={(e) => setContent({ ...content, content: e })}
            placeholder="Example placeholder..."
          />
          <Button color="primary" className="mt-3" onClick={handleSubmitTerms}>
            {t('SETTINGS.TERMSANDCONDITIONS.SUBMITBUTTONTITLE')}
          </Button>
        </Container>
      </CardWrapper>
    </>
  );
}
