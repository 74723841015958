import React, { useState, useEffect } from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import FullScreen from './component/FullScreen';
import { FlagIcon } from 'react-flag-kit';
import Notifications from './component/Notifications';

const headingMapping = {
  en: 'English',
  ru: 'Russian'
};

const flagMapping = {
  en: 'US',
  ru: 'RU'
};

const HeaderDots = () => {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [selectedFlag, setSelectedFlag] = useState('US');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    setSelectedLanguage(storedLanguage ? headingMapping[storedLanguage] : 'English');
    setSelectedFlag(storedLanguage ? flagMapping[storedLanguage] : 'US');
  }, []);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(headingMapping[language]);
    setSelectedFlag(flagMapping[language]);
    localStorage.setItem('selectedLanguage', language);
  };

  return (
    <div className="d-flex align-items-center popover-header-wrapper">
      <UncontrolledDropdown>
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex btn-transition-none align-items-center">
          <Button>
            <span className="btn-wrapper--icon">
              <FlagIcon code={selectedFlag} size={18} />
            </span>
            <span className="btn-wrapper--label">{selectedLanguage}</span>
          </Button>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-md overflow-hidden p-0">
          <DropdownItem onClick={() => handleLanguageChange('en')}>English</DropdownItem>
          <DropdownItem onClick={() => handleLanguageChange('ru')}>Russian</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <FullScreen />
      <span className="d-inline-block pr-2">
        <Notifications />
      </span>
    </div>
  );
};

export default HeaderDots;
