import React from 'react';

import clsx from 'clsx';
import { useSelector } from 'react-redux';

import HeaderUserbox from '../../layout-components/HeaderUserbox';
import SidebarToggle from '../SidebarToggle';
import HeaderDots from 'layout-components/HeaderDots';

const Header = () => {
  const { headerShadow, headerBgTransparent } = useSelector((state) => ({
    headerShadow: state.theme.headerShadow,
    headerBgTransparent: state.theme.headerBgTransparent
  }));

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <SidebarToggle />
        </div>
        <div className="app-header--pane">
          <HeaderDots />
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

export default Header;
