import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { PulseLoader } from 'react-spinners';

// Layout Blueprints

import {
  LeftSidebar,
  MinimalLayout
  // PresentationLayout
} from './layout-blueprints';
import PageError404 from './pages/PageError404';
import { lazy } from 'react';
import PendingOrders from 'pages/orders/pending';
import ReturnedOrders from 'pages/orders/returned';
import ProcessingOrders from 'pages/orders/processing';
import PartnerStartedOrders from 'pages/orders/partnerStarted';
import DeliveredOrders from 'pages/orders/delivered';
import CancelledOrders from 'pages/orders/cancelled';
import FailedOrders from 'pages/orders/failed';
import ConfirmedOrders from 'pages/orders/confirmed';
import TermsAndConditions from 'pages/settings/termsAndConditions';
import PlanConfig from 'pages/settings/planConfig';
import { useSelector } from 'react-redux';

const Login = lazy(() => import('pages/login'));
const Dashboard = lazy(() => import('pages/dashboard'));
const AllOrders = lazy(() => import('pages/orders/all'));
const NewOrder = lazy(() => import('pages/orders/details'));
const AllBanners = lazy(() => import('pages/banners/all'));
const AddBanner = lazy(() => import('pages/banners/add'));
const AllCategories = lazy(() => import('pages/categories/all'));
const AddUpdateCategory = lazy(() => import('pages/categories/addUpdate'));
const AllServies = lazy(() => import('pages/services/all'));
const AddUpdateService = lazy(() => import('pages/services/addUpdate'));
const AllReviews = lazy(() => import('pages/reviews/all'));
const AllPayments = lazy(() => import('pages/payments'));
const ContactUsMessages = lazy(() => import('pages/contactUs'));
const AllAppointment = lazy(() => import('pages/appointments'));
const AllTimeSlots = lazy(() => import('pages/time-slots'));
const Partners = lazy(() => import('pages/partners/all'));
const PartnersDetails = lazy(() => import('pages/partners/partnerDetails'));
const SubscriberPartners = lazy(() => import('pages/partners/subscribers'));
const SubscriberPartnersDetails = lazy(() =>
  import('pages/partners/subscribers/subscriberDetails')
);
const PartnersChat = lazy(() => import('pages/partners/chat'));
const Admins = lazy(() => import('pages/admins/all'));
const AdminDetails = lazy(() => import('pages/admins/adminDetails'));
const Customers = lazy(() => import('pages/customers/all'));
const CustomerDetails = lazy(() => import('pages/customers/customerDetails'));
const StoreSetup = lazy(() => import('pages/settings/storeSetup'));
const MailConfig = lazy(() => import('pages/settings/mailConfig'));
const SMSModule = lazy(() => import('pages/settings/smsModule'));
const PaymentConfig = lazy(() => import('pages/settings/paymentConfig'));
const PrivacyPolicy = lazy(() => import('pages/settings/privacyPolicy'));

const Routes = () => {
  const location = useLocation();
  const roleId = useSelector((state) => state.user.roleId);
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PulseLoader color={'var(--primary)'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the this page
          </div>
        </div>
      </>
    );
  };
  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          {roleId === 1 ? (
            <>
              <Route
                path={[
                  '/dashboard',
                  '/orders',
                  '/orders/pending',
                  '/orders/confirmed',
                  '/orders/returned',
                  '/orders/processing',
                  '/orders/partner-started',
                  '/orders/delivered',
                  '/orders/cancelled',
                  '/orders/failed',
                  '/orders/new',
                  '/orders/view/:id',
                  '/banners',
                  '/banners/new',
                  '/banners/edit/:id',
                  '/categories',
                  '/categories/new',
                  '/categories/edit/:id',
                  '/services',
                  '/services/edit/:id',
                  '/reviews',
                  '/payments',
                  '/appointments',
                  '/customers-contacts',
                  '/time-slots',
                  '/partners',
                  '/partners/new',
                  '/partners/edit/:id',
                  '/partners/subscribers',
                  '/partners/chat',
                  '/admins',
                  '/admins/new',
                  '/admins/edit/:id',
                  '/customers',
                  '/customers/:id',
                  '/settings/store-setup',
                  '/settings/mail-config',
                  '/settings/sms-module',
                  '/settings/payment-config',
                  '/settings/terms',
                  '/settings/privacy-policy',
                  '/settings/plan-config'
                ]}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/dashboard" exact component={Dashboard} />
                      <Route path="/orders" exact component={AllOrders} />
                      <Route path="/orders/pending" exact component={PendingOrders} />
                      <Route path="/orders/confirmed" exact component={ConfirmedOrders} />
                      <Route path="/orders/returned" exact component={ReturnedOrders} />
                      <Route path="/orders/processing" exact component={ProcessingOrders} />
                      <Route
                        path="/orders/partner-started"
                        exact
                        component={PartnerStartedOrders}
                      />
                      <Route path="/orders/delivered" exact component={DeliveredOrders} />
                      <Route path="/orders/cancelled" exact component={CancelledOrders} />
                      <Route path="/orders/failed" exact component={FailedOrders} />
                      <Route path="/orders/new" exact component={NewOrder} />
                      <Route path="/orders/view/:id" exact component={NewOrder} />
                      <Route path="/banners" exact component={AllBanners} />
                      <Route path="/time-slots" component={AllTimeSlots} />
                      <Route path="/banners/new" exact component={AddBanner} key={1} />
                      <Route path="/banners/edit/:id" exact component={AddBanner} key={2} />
                      <Route path="/categories" exact component={AllCategories} />
                      <Route path="/categories/new" exact component={AddUpdateCategory} key={1} />
                      <Route
                        path="/categories/edit/:id"
                        exact
                        component={AddUpdateCategory}
                        key={2}
                      />
                      <Route path="/services" exact component={AllServies} />
                      <Route path="/services/new" exact component={AddUpdateService} key={1} />
                      <Route path="/services/edit/:id" exact component={AddUpdateService} key={2} />
                      <Route path="/reviews" exact component={AllReviews} />
                      <Route path="/payments" exact component={AllPayments} />
                      <Route path="/appointments" exact component={AllAppointment} />
                      <Route path="/customers-contacts" exact component={ContactUsMessages} />
                      <Route path="/settings/store-setup" exact component={StoreSetup} />
                      <Route path="/settings/mail-config" exact component={MailConfig} />
                      <Route path="/settings/sms-module" exact component={SMSModule} />
                      <Route path="/settings/payment-config" exact component={PaymentConfig} />
                      <Route path="/settings/plan-config" exact component={PlanConfig} />
                      <Route path="/settings/terms" exact component={TermsAndConditions} />
                      <Route path="/settings/privacy-policy" exact component={PrivacyPolicy} />
                      <Route path="/partners" exact component={Partners} />
                      <Route path="/partners/new" exact component={PartnersDetails} key={1} />
                      <Route path="/partners/edit/:id" exact component={PartnersDetails} key={2} />
                      <Route path="/partners/subscribers" exact component={SubscriberPartners} />
                      <Route
                        path="/partners/subscribers/:id"
                        exact
                        component={SubscriberPartnersDetails}
                      />
                      <Route path="/partners/chat" exact component={PartnersChat} />
                      <Route path="/admins" exact component={Admins} />
                      <Route path="/admins/new" exact component={AdminDetails} />
                      <Route path="/admins/edit/:id" exact component={AdminDetails} />
                      <Route path="/customers" exact component={Customers} />
                      <Route path="/customers/:id" exact component={CustomerDetails} />
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={Login} />
                    <Route path="/404" component={PageError404} />
                    <Route path="/*" element={() => <Redirect to="/404" />} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </>
          ) : (
            <>
              <Route
                path={[
                  '/dashboard',
                  '/orders',
                  '/orders/pending',
                  '/orders/confirmed',
                  '/orders/returned',
                  '/orders/processing',
                  '/orders/partner-started',
                  '/orders/delivered',
                  '/orders/cancelled',
                  '/orders/failed',
                  '/orders/:id',
                  '/services',
                  '/services/new',
                  '/services/edit/:id',
                  '/partners/chat'
                ]}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/dashboard" exact component={Dashboard} />
                      <Route path="/orders" exact component={AllOrders} />
                      <Route path="/orders/pending" exact component={PendingOrders} />
                      <Route path="/orders/confirmed" exact component={ConfirmedOrders} />
                      <Route path="/orders/returned" exact component={ReturnedOrders} />
                      <Route path="/orders/processing" exact component={ProcessingOrders} />
                      <Route
                        path="/orders/partner-started"
                        exact
                        component={PartnerStartedOrders}
                      />
                      <Route path="/orders/delivered" exact component={DeliveredOrders} />
                      <Route path="/orders/cancelled" exact component={CancelledOrders} />
                      <Route path="/orders/failed" exact component={FailedOrders} />
                      <Route path="/orders/new" exact component={NewOrder} />
                      <Route path="/orders/view/:id" exact component={NewOrder} />
                      <Route path="/services" exact component={AllServies} />
                      <Route path="/services/new" exact component={AddUpdateService} key={1} />
                      <Route path="/services/edit/:id" exact component={AddUpdateService} key={2} />
                      <Route path="/partners/chat" exact component={PartnersChat} />
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={('/404', '*')}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/login" component={Login} />
                      <Route path="/404" element={PageError404} />
                      <Route path="*" element={() => <Redirect to="/404" />} />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
            </>
          )}
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
