import React from 'react';
import { PageTitle } from 'layout-components';
import TermsAndConditionsComponent from './components/TermsAndConditionsComponent';
import { useTranslation } from 'react-i18next';

export default function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle titleHeading={t('SETTINGS.TERMSANDCONDITIONS.TITLE')} />
      <TermsAndConditionsComponent />
    </>
  );
}
