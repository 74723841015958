import React from 'react';
import OrderTab from './components/Tab';
import { PageTitle } from 'layout-components';
import { useTranslation } from 'react-i18next';

export default function FailedOrders() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle titleHeading={t('ORDERS.FAILED.TITLE')} />
      <OrderTab />
    </>
  );
}
