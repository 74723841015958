import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET_USER, post } from 'api';

// User Thunk
export const login = createAsyncThunk('user/login', async (data) => {
  // const res = await post(LOGIN, data);
  return data;
});

export const getUser = createAsyncThunk('user/getUser', async () => {
  const res = await post(GET_USER);
  return res.user;
});

const initialState = {
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  description: null,
  image: null,
  roleId: 0,
  subscriptionId: null
};

const themeSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    updateUser: (state, action) => {
      const updatedState = { ...state, ...action.payload };
      return updatedState;
    }
  },
  extraReducers: {
    [login.fulfilled]: (_, action) => {
      return action.payload;
    },
    [getUser.fulfilled]: (_, action) => {
      return action.payload;
    }
  }
});
export const { logout, updateUser } = themeSlice.actions;
export default themeSlice.reducer;
