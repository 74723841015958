import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContainerIds = {
  Success: 'A',
  Error: 'B'
};

export const ToastContainers = () => {
  <>
    <ToastContainer
      enableMultiContainer
      containerId={ContainerIds.Success}
      position={toast.POSITION.TOP_RIGHT}
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      theme="light"
      type="success"
    />
    <ToastContainer
      enableMultiContainer
      containerId={ContainerIds.Error}
      position={toast.POSITION.TOP_RIGHT}
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      theme="light"
      type="error"
    />
  </>;
};

export const showToast = (message, type) => {
  toast[type](message, {
    containerId: type === 'success' ? ContainerIds.Success : ContainerIds.Error,
    style: {
      color: 'white'
    }
  });
};
