import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, Row, Col, InputGroup, Input, Button, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import OrdersTable from 'components/OrdersTable';
import { get, GET_ORDERS_BY_STATUS } from 'api';

const ConfirmedOrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [deliveredDate, setDeliveredDate] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await get(GET_ORDERS_BY_STATUS + 'Confirmed');
        setOrders(res);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchOrders();
  }, []);

  const ordersColumns = useMemo(
    () => [
      { name: t('ORDERS.ORDERSTABLE.COLUMNORDER'), className: 'font-weight-bold' },
      {
        name: t('ORDERS.ORDERSTABLE.COLUMNDELIVERYDATE'),
        className: 'text-center font-weight-bold'
      },
      {
        name: t('ORDERS.ORDERSTABLE.COLUMNTIMESLOT'),
        className: 'text-center font-weight-bold'
      },
      {
        name: t('ORDERS.ORDERSTABLE.COLUMNCUSTOMER'),
        className: 'text-center font-weight-bold'
      },
      { name: t('ORDERS.ORDERSTABLE.COLUMNTOTAL'), className: 'text-center font-weight-bold' }
    ],
    []
  );

  return (
    <Card className="card-box mb-5">
      <CardHeader>
        <div className="card-header--title">
          <Row>
            <Col sm={12} md={5} className="my-2">
              <InputGroup>
                <Input placeholder={t('ORDERS.ORDERSTABLE.SEARCHPLACEHOLDER')} />
                <Button color="primary">{t('ORDERS.ORDERSTABLE.SEARCHBTNTITLE')}</Button>
              </InputGroup>
            </Col>
            <Col sm={12} md={5} className="my-2">
              <InputGroup>
                <DatePicker
                  className="form-control"
                  selected={deliveredDate}
                  onChange={(date) => setDeliveredDate(date)}
                />
                <InputGroupText>
                  <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                </InputGroupText>
              </InputGroup>
            </Col>
          </Row>
        </div>
      </CardHeader>
      <div className="card-body px-0 pt-2 pb-3 table-responsive-md">
        <OrdersTable columns={ordersColumns} orders={orders} />
      </div>
    </Card>
  );
};

export default ConfirmedOrdersTable;
