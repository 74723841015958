import React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

import { setSidebarToggleMobile } from 'store/reducers/themeSlice';

import { SidebarHeader, SidebarMenu } from 'layout-components';

const Sidebar = () => {
  const { sidebarStyle, sidebarShadow, sidebarToggleMobile } = useSelector((state) => ({
    sidebarStyle: state.theme.sidebarStyle,
    sidebarShadow: state.theme.sidebarShadow,
    sidebarToggleMobile: state.theme.sidebarToggleMobile
  }));
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow
        })}>
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div
        onClick={() => dispatch(setSidebarToggleMobile(!sidebarToggleMobile))}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile
        })}
      />
    </>
  );
};

export default Sidebar;
